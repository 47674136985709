<template>
  <div class="workDetail">
    <Nav></Nav>
    <li class="con" v-for="(item, index) in list" :key="index">
      <div class="img">
        <img v-lazy="item.url" alt="" />
      </div>
      <div class="text" v-if="index == 0">
        <div class="left">
          <div class="title">百年老字号黃勝記品牌升级</div>
          <p class="chinese"></p>
          <p class="chinese-min"> “黄胜记距今已有166年历史，一个五代人的品牌，始终在鼓浪屿没离开过。”

老厦门人们的心里，时刻有一种传承的记忆，黄胜记，大概就是这其中之一。

清道光⼆⼗⼆年（1842年），年仅16岁的黄知江从漳州东山出发，提着自家手作肉松肉干，乘着斗篷小船，一路沿途叫卖。彼时厦门港正值繁荣，很快，黄知江就找到了自己的市场。

因其“选料精良，配料独特，加之精⼯细作”，黄知江所作的肉松⾊泽⾦黄，灿若黄⾦，味道香鲜回味，广受好评。老字号“黄⾦⾹”（黄胜记前身）因此应运而生。到抗战前后，黄⾦⾹已经声名远播，在台湾、⾹港、新加坡、马尼拉、槟榔屿等地都很受欢迎。

这正是黄胜记的品牌由来。1978年⾄今，黄⾦⾹胜记⾁松店发展⽇趋成熟，产品⼯艺精湛、配料考究，质量上乘、风味独特，产品销量稳步上升，百年⽼店回复当年风光，⼀展辉煌形势，已成为⼈尽皆知的百年⽼字号。

作为一个百年老字号品牌，黄胜记似乎还停留在上世纪的氛围里——坚持纯正手作，坚持低价销售。</p>
          <p class="english">

          </p>
        </div>
        <div class="right">
          <p><span>Creative Director</span>：黄志勤</p>
          <p><span>Art Guidance</span>：张松燃</p>
          <p><span>Designer</span>：大苗</p>
        </div>
      </div>
      <!-- <div class="text" v-if="index == 1">
      <div class="left">
      <p class="chinese-min"></p>
      </div>
      </div> -->
    </li>
    <Footer></Footer>
  </div>
</template>

<script>
import Nav from "../components/Nav";
import Footer from  '../components/FooterInside.vue'
export default {
  components: {
    Nav,
    Footer
  },
  data() {
    return {
      list: [
              {
                    url: require('../assets/work-14/x1.jpg')
                },
                {
                    url: require('../assets/work-14/x2.jpg')
                },
                {
                    url: require('../assets/work-14/x3.jpg')
                },
                {
                    url: require('../assets/work-14/x4.jpg')
                },
                {
                    url: require('../assets/work-14/x5.jpg')
                },
                {
                    url: require('../assets/work-14/x6.jpg')
                },
                {
                    url: require('../assets/work-14/x7.jpg')
                },
                {
                    url: require('../assets/work-14/x8.jpg')
                },
                {
                    url: require('../assets/work-14/x9.jpg')
                },
                {
                    url: require('../assets/work-14/x10.jpg')
                },
                {
                    url: require('../assets/work-14/x11.jpg')
                },
                {
                    url: require('../assets/work-14/x12.jpg')
                },
                {
                    url: require('../assets/work-14/x13.jpg')
                },
                {
                    url: require('../assets/work-14/x14.jpg')
                },
                {
                    url: require('../assets/work-14/x15.jpg')
                },
                {
                    url: require('../assets/work-14/x16.jpg')
                },
                {
                    url: require('../assets/work-14/x17.jpg')
                },
                {
                    url: require('../assets/work-14/x18.jpg')
                },
                {
                    url: require('../assets/work-14/x19.jpg')
                },
                {
                    url: require('../assets/work-14/x20.jpg')
                },
                {
                    url: require('../assets/work-14/x21.jpg')
                },
                {
                    url: require('../assets/work-14/x22.jpg')
                },
                {
                    url: require('../assets/work-14/x23.jpg')
                },
                {
                    url: require('../assets/work-14/x24.jpg')
                },
      ],
    };
  },
};
</script>


<style scoped lang="less">
.workDetail {
  .con {
    width: 100%;

    .img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      img {
        width: 100%;
      }

      img[lazy="loading"] {
        width: 10%;
      }
    }

    .text {
      display: flex;
      justify-content: space-between;
      padding: 0.3rem;

      .left {
        width: 850px;

        .title {
          font-size: 26px;
          margin-bottom: 30px;
        }

        .chinese {
          font-size: 15px;
          line-height: 0px;
          margin-bottom: 0px;
          font-weight: 400;
        }

        .chinese-min {
          font-size: 15px;
          line-height: 28px;
          font-weight: 320;
          span{font-weight: 500;}
        }

        .english {
          font-size: 15px;
          line-height: 22px;
          font-weight: 320;
        }
      }

      .right {
        margin-top: 64px;
        margin-right: 0.3rem;
        font-size: 14px;

        span {
          display: inline-block;
          width: 130px;
        }

        p {
          line-height: 12px;
        }
      }
    }
  }
}

/* 宽度大于1900*/
@media only screen and (min-width:1900px) {

  .text {
    .left {
      width: 950px !important;

      .title {
        font-size: 30px !important;
      }

      .chinese {
        font-size: 16px !important;
        line-height: 18px !important;
      }

      .chinese-min {
        font-size: 17px !important;
        line-height: 30px !important;
      }

      .english {
        font-size: 15px !important;
        line-height: 22px !important;
      }
    }

    .right {
      margin-top: 68px !important;
      margin-right: 0.3rem !important;
      font-size: 16px !important;

      span {
        display: inline-block;
        width: 140px;
      }

      p {
        line-height: 12px;
      }
    }
  }


}

/* 屏幕宽度大于1600并且小于1899 */
@media only screen and (min-width:1600px) and (max-width:1899px) {


  .text {
    .left {
      width: 900px !important;

      .title {
        font-size: 27px !important;
        margin-bottom: 20px;
      }

      .chinese {
        font-size: 16px !important;
        line-height: 28px !important;
      }

      .chinese-min {
        font-size: 16px !important;
        line-height: 28px !important;
      }

      .english {
        font-size: 14px !important;
        line-height: 22px !important;
      }
    }

    .right {
      margin-top: 65px !important;
      margin-right: 0.3rem !important;
      font-size: 14px !important;

      span {
        display: inline-block;
        width: 120px;
      }

      p {
        line-height: 12px;
      }
    }
  }

}

/* 宽度大于1100并且小于1400 */
@media only screen and (min-width:1100px) and (max-width:1399px) {

  .text {
    .left {
      width: 680px !important;

      .title {
        font-size: 24px !important;
        margin-bottom: 20px;
      }

      .chinese {
        font-size: 25px !important;
        line-height: 0px;
        margin-bottom: 0px;
      }

      .chinese-min {
        font-size: 14px !important;
        line-height: 24px !important;
        font-weight: 320;
      }

      .english {
        font-size: 14px !important;
        line-height: 22px !important;
        font-weight: 320;
      }
    }

    .right {
      margin-top: 45px !important;
      margin-right: 0.3rem !important;
      font-size: 13px !important;

      span {
        display: inline-block;
        width: 120px;
      }

      p {
        line-height: 12px;
      }
    }
  }
}

/* 宽度大于768并且小于1100 */
@media only screen and (min-width:768px) and (max-width:1099px) {
  .text {
    .left {
      width: 500px !important;

      .title {
        font-size: 24px !important;
        margin-bottom: 18px;
      }

      .chinese {
        font-size: 25px !important;
        line-height: 0px;
        margin-bottom: 0px;
      }

      .chinese-min {
        font-size: 12px !important;
        line-height: 24px !important;
        font-weight: 320;
      }

      .english {
        font-size: 12px !important;
        line-height: 22px !important;
        font-weight: 320;
      }
    }

    .right {
      margin-top: 45px !important;
      margin-right: 0.3rem !important;
      font-size: 12px !important;

      span {
        display: inline-block;
        width: 130px;
      }

      p {
        line-height: 10px;
      }
    }
  }
}

/* 宽度小于768 */
@media only screen and (max-width:768px) {
  .workDetail {
    .con {
      .img {
        width: 100%;
      }

      .text {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 30px 0;

        .left {
          width: 96%;

          .title {
            margin-left: 0px;
            font-size: 22px;
            margin-bottom: 20px;
          }

          .chinese-min {
            font-size: 15px !important;
            line-height: 26px !important;
          }

          .english {
            font-size: 15px;
            line-height: 20px !important;
          }
        }

        .right {
          width: 96%;
          padding: 0 !important;
          margin: 0 !important;
          padding-left: 0 !important;
          font-size: 13px;

          p {
            line-height: 10px;
          }
        }
      }
    }
  }
}
</style>